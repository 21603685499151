<template>
    <div>
        <b-card class="text-center">
            <b-tabs nav-class="mb-3">
                <b-tab
                    title="Project settings"
                    active
                >
                    <project-settings />
                </b-tab>
                <b-tab title="Product images">
                    <product-image-upload />
                </b-tab>
                <b-tab title="Allowed item bulk scans">
                    <allowed-item-bulk-scans />
                </b-tab>
            </b-tabs>
        </b-card>
    </div>
</template>

<script>
import ProductImageUpload from '@/components/Project/ProductImageUpload.vue'
import AllowedItemBulkScans from '@/components/Project/AllowedItemBulkScans.vue'
import ProjectSettings from '@/components/Project/ProjectSettings.vue'
import {
    BCard,
    BTab,
    BTabs,
} from 'bootstrap-vue'
import Features from '@/services/Features'
import { FeatureShipments } from '@/constants'
import Access from '@/services/Access'

export default {
    name: 'settings.profile',
    components: {
        ProductImageUpload,
        AllowedItemBulkScans,
        ProjectSettings,
        BTabs,
        BTab,
        BCard,
    },
    data() {
        return {
            currentTab: 'project-settings',
        }
    },
    methods: {
        isShipmentModuleAvailable() {
            return Features.checkFeature(FeatureShipments)
                && Access.checkAccess('shipments', Access.LEVEL_READ)
        },
    },
}
</script>
