<template>
    <div>
        <div class="m-form m-form--fit m-form--label-align-right">
            <div class="m-portlet__body">
                <div v-if="errors.length > 0" class="form-group m-form__group row">
                    <div class="alert alert-danger col-12">
                        <ul class="error-list">
                            <li v-for="error in errors">
                                {{ error }}
                            </li>
                        </ul>
                    </div>
                </div>

                <div v-if="loading" class="project-allowed-bulk-items__loading text-center">
                    <h4>Loading...</h4>
                </div>
                <div v-if="!loading && items.length === 0" class="project-allowed-bulk-items__loading text-center">
                    <p>No items found</p>
                </div>
                <template v-else>
                    <div v-for="(item, index) in items" :key="index" class="form-group m-form__group row">
                        <label class="col-1 col-form-label">
                            {{ index + 1 }}.
                        </label>
                        <label :for="'project-allowed-bulk-items__item-ean-' + index" class="col-1 col-form-label">
                            Ean
                        </label>
                        <div class="col-4">
                            <input :id="'project-allowed-bulk-items__item-ean-' + index" v-model="item.ean" type="text" class="form-control">
                        </div>
                        <label :for="'project-allowed-bulk-items__item-qty-' + index" class="col-2 col-form-label">
                            Default quantity
                        </label>
                        <div class="col-2">
                            <input :id="'project-allowed-bulk-items__item-qty-' + index" v-model="item.default_quantity" type="text" class="form-control">
                        </div>
                        <div class="col-1 text-right">
                            <button class="btn btn-danger" @click="deleteItem(item)">
                                <i class="fa fa-trash-o" />
                            </button>
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <hr class="mt-2 mb-2">
        <div>
            <div
                v-if="!loading"
                class="mt-1 mr-1 float-left"
            >
                <b-button
                    variant="success"
                    @click="addEmptyItem"
                >
                    Add Item
                </b-button>
            </div>
            <b-button
                variant="primary"
                :disabled="loading"
                @click="saveAllowedItems"
                class="mt-1 mr-1 float-left"
            >
                {{ loading ? 'Loading...' : 'Save' }}
            </b-button>
        </div>
    </div>
</template>

<style>
</style>
<script>
import Axios from 'axios'
import {
    BButton,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { heightTransition } from '@core/mixins/ui/transition'

export default {
    components: {
        BButton,
        // eslint-disable-next-line vue/no-unused-components
        ToastificationContent,
    },
    mixins: [heightTransition],
    data() {
        return {
            errors: [],
            items: [],
            loading: false,
        }
    },
    mounted() {
        this.initTrHeight()
    },
    created() {
        this.loadAllowedScans()
    },
    methods: {
        loadAllowedScans() {
            this.loading = true
            Axios({
                url: `${process.env.VUE_APP_API_HTTP_ROOT}project/get-allowed-item-bulk-scans`,
                data: {},
                method: 'POST',
            })
                .then(resp => {
                    this.loading = false
                    this.items = resp.data.data
                })
                .catch(() => {
                    this.loading = false
                    this.items = []
                })
        },
        addEmptyItem() {
            const tmpItem = {
                ean: '',
                default_quantity: 1,
            }

            this.items.push(tmpItem)
        },
        deleteItem(item) {
            const index = this.items.indexOf(item)
            this.items.splice(index, 1)
        },
        saveAllowedItems() {
            this.loading = true
            this.errors = []
            Axios({
                url: `${process.env.VUE_APP_API_HTTP_ROOT}project/save-allowed-item-bulk-scans`,
                data: { allowed_item_bulk_scans: this.items },
                method: 'POST',
            })
                .then(() => {
                    this.loadAllowedScans()
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Success',
                            icon: 'CheckIcon',
                            text: 'Data saved successfully!',
                            variant: 'success',
                        },
                    })
                })
                .catch(err => {
                    this.loading = false
                    for (const errorGroupKey of Object.keys(err.response.data.errors)) {
                        const errorGroup = err.response.data.errors[errorGroupKey]
                        for (const errorMessageKey of Object.keys(errorGroup)) {
                            const errorMessage = err.response.data.errors[errorGroupKey][errorMessageKey]
                            this.errors = this.errors.concat(errorMessage)
                        }
                    }
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Whoops',
                            icon: 'XIcon',
                            text: '' + this.errors,
                            variant,
                        },
                    })
                })
        },
    },
}
</script>
