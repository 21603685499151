<template>
    <div>
        <b-form>
            <b-form-row>
                <b-col md="6" offset-md="1">
                    <b-form-group
                        label="File (.csv)"
                        label-for="s-file"
                        label-cols-md="4"
                    >
                        <b-form-file
                            id="s-file"
                            v-model="file"
                            accept=".csv"
                            placeholder="Choose a file or drag and drop it here..."
                        />
                    </b-form-group>
                </b-col>
            </b-form-row>
            <b-form-row>
                <b-col md="6" offset-md="1">
                    <b-form-group
                        label="Override existing"
                        label-for="s-override"
                        label-cols-md="4"
                    >
                        <b-form-checkbox
                            id="s-override"
                            v-model="fileData.overrideExisting"
                            variant="primary"
                        >
                            <span style="font-size: 10px; float: left;">If this checkbox is checked, after upload all existing product images will be deleted and new ones in this file will be imported.</span>
                        </b-form-checkbox>
                    </b-form-group>
                </b-col>
            </b-form-row>
            <hr class="mt-2 mb-2">
            <b-col md="3">
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-1"
                    :disabled="downloadGenerating || uploadingImageCsv"
                    @click="submitProductImageCsv"
                >
                    <b-spinner v-if="uploadingImageCsv" small />
                    Upload
                </b-button>
                <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="flat-dark"
                    :disabled="downloadGenerating || uploadingImageCsv"
                    @click="downloadCsv"
                >
                    <b-spinner v-if="downloadGenerating" small />
                    Download existing images
                </b-button>
            </b-col>
        </b-form>
    </div>
</template>

<style>
</style>
<script>
import Axios from 'axios'
import {
    BFormRow, BCol, BFormGroup, BForm, BButton, BSpinner, BFormFile, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
    components: {
        BSpinner,
        BButton,
        BFormRow,
        BCol,
        BFormGroup,
        BForm,
        BFormFile,
        BFormCheckbox,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            uploadingImageCsv: false,
            fileUploaded: false,
            downloadGenerating: false,
            errors: [],
            file: null,
            blob: null,
            fileData: {
                file: '',
                fileName: '',
                overrideExisting: false,
            },
        }
    },
    methods: {
        submitProductImageCsv() {
            this.uploadingImageCsv = true
            this.fileUploaded = false
            this.errors = []
            this.filePickedDragAndDrop(this.file)
            setTimeout(() => {
                const data = {
                    should_override: this.fileData.overrideExisting ? 1 : 0,
                    file: this.fileData.file,
                }
                Axios({
                    url: `${process.env.VUE_APP_API_HTTP_ROOT}project/upload-image-import-csv`,
                    data,
                    method: 'POST',
                })
                    .then(() => {
                        this.uploadingImageCsv = false
                        this.fileUploaded = true
                    })
                    .catch(err => {
                        for (const errorGroupKey of Object.keys(err.response.data.errors)) {
                            const errorGroup = err.response.data.errors[errorGroupKey]
                            this.errors = this.errors.concat(errorGroup)
                        }
                        this.uploadingImageCsv = false
                    })
            }, 1000)
        },
        filePickedDragAndDrop(fileInfo) {
            const file = fileInfo
            if (file) {
                this.fileData.fileName = file.name
                this.getBase64(file).then(
                    data => {
                        this.fileData.file = data
                        this.$forceUpdate()
                    },
                )
            }
        },
        getBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = () => resolve(reader.result.split(',').pop())
                reader.onerror = error => reject(error)
            })
        },
        downloadCsv() {
            this.downloadGenerating = true
            Axios({ url: `${process.env.VUE_APP_API_HTTP_ROOT}project/download-image-import-csv`, data: {}, method: 'POST' })
                .then(resp => {
                    this.exportToCsv('img.csv', resp.data.data)
                    this.downloadGenerating = false
                })
                .catch(() => {
                    this.downloadGenerating = false
                })
        },
        exportToCsv(filename, rows) {
            const processRow = row => {
                let finalVal = ''
                for (let j = 0; j < row.length; j += 1) {
                    let innerValue = row[j] === null ? '' : row[j].toString()
                    if (row[j] instanceof Date) {
                        innerValue = row[j].toLocaleString()
                    }
                    let result = innerValue.replace(/"/g, '""')
                    if (result.search(/("|,|\n)/g) >= 0) {
                        result = `"${result}"`
                    }
                    if (j > 0) {
                        finalVal += ','
                    }
                    finalVal += result
                }
                return `${finalVal}\r\n`
            }

            let csvFile = ''
            for (let i = 0; i < rows.length; i += 1) {
                csvFile += processRow(rows[i])
            }

            const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' })
            if (navigator.msSaveBlob) { // IE 10+
                navigator.msSaveBlob(blob, filename)
            } else {
                const link = document.createElement('a')
                if (link.download !== undefined) { // feature detection
                    // Browsers that support HTML5 download attribute
                    const url = URL.createObjectURL(blob)
                    link.setAttribute('href', url)
                    link.setAttribute('download', filename)
                    link.style.visibility = 'hidden'
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                }
            }
        },
    },
}
</script>
