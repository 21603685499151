<template>
    <div>
        <b-spinner v-if="loading" variant="primary"/>
        <b-form v-if="!loading">
            <b-form-row>
                <b-col md="6" offset-md="1">
                    <b-form-group
                        label="Project name"
                        label-for="s-project-name"
                        label-cols-md="4"
                    >
                        <b-form-input v-model="name" />
                    </b-form-group>

                </b-col>
            </b-form-row>
            <b-form-row>
                <b-col md="6" offset-md="1">
                    <b-form-group
                        label="Dimensions unit"
                        label-for="s-dimensions-unit"
                        label-cols-md="4"
                    >
                        <v-select
                            id="s-dimensions-unit"
                            v-model="settings.lengthUnit"
                            label="text"
                            :options="[{ text: 'mm', value: 'mm' }, { text: 'cm', value: 'cm' }, { text: 'in', value: 'in' }]"
                            :reduce="text => text.value"
                        />
                    </b-form-group>
                </b-col>
            </b-form-row>
            <b-form-row>
                <b-col md="6" offset-md="1">
                    <b-form-group
                        label="Weight unit"
                        label-for="s-weight-unit"
                        label-cols-md="4"
                    >
                        <v-select
                            id="s-weight-unit"
                            v-model="settings.weightUnit"
                            label="text"
                            :options="[{ text: 'kg', value: 'kg' }, { text: 'g', value: 'g' }, { text: 'lb', value: 'lb' }, { text: 'oz', value: 'oz' }]"
                            :reduce="text => text.value"
                        />
                    </b-form-group>
                </b-col>
            </b-form-row>
            <b-form-row>
                <b-col md="6" offset-md="1">
                    <b-form-group
                        label="Item mapping type"
                        label-for="s-mapping-type"
                        label-cols-md="4"
                    >
                        <v-select
                            id="s-mapping-type"
                            v-model="settings.itemMappingType"
                            label="text"
                            :options="[{ text: 'EAN', value: 'itemMappingTypeEan' }, { text: 'Linnworks', value: 'itemMappingTypeLinnworks' }]"
                            :reduce="text => text.value"
                        />
                    </b-form-group>
                </b-col>
            </b-form-row>
            <hr class="mt-2 mb-2">
            <b-col md="1">
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-1"
                    @click="saveSettings"
                >
                    Submit
                </b-button>
            </b-col>
        </b-form>
    </div>
</template>

<style>
</style>
<script>
import Axios from 'axios'
import {
    BFormRow, BCol, BFormGroup, BForm, BButton, BSpinner, BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import Access from '../../services/Access'
import Features from '../../services/Features'

export default {
    components: {
        BSpinner,
        BButton,
        BFormRow,
        BCol,
        BFormGroup,
        BForm,
        BFormInput,
        // eslint-disable-next-line vue/no-unused-components
        ToastificationContent,
        vSelect,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            Access,
            Features,
            errors: [],
            loading: false,
            settings: {
                weightUnit: 'g',
                lengthUnit: 'mm',
            },
            name: '',
        }
    },
    created() {
        this.loadSettings()
    },
    methods: {
        loadSettings() {
            this.loading = true
            Axios({
                url: `${process.env.VUE_APP_API_HTTP_ROOT}project/get-settings`,
                data: {},
                method: 'POST',
            })
                .then(resp => {
                    this.loading = false
                    this.settings = resp.data.settings
                    this.name = resp.data.name
                })
                .catch(() => {
                    this.loading = false
                })
        },
        saveSettings() {
            this.loading = true
            this.errors = []
            const data = {
                ...this.settings,
                name: this.name,
            }
            Axios({
                url: `${process.env.VUE_APP_API_HTTP_ROOT}project/set-settings`,
                data: data,
                method: 'POST',
            })
                .then(() => {
                    this.$store.dispatch('fetchProjectSettings')
                        .then(() => {
                            this.loading = false
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Success!',
                                    icon: 'CheckIcon',
                                    text: 'Project settings saved successfully.',
                                    variant: 'primary',
                                },
                            })
                        })
                })
                .catch(err => {
                    this.loading = false
                    for (const errorGroupKey of Object.keys(err.response.data.errors)) {
                        const errorGroup = err.response.data.errors[errorGroupKey]
                        for (const errorMessageKey of Object.keys(errorGroup)) {
                            const errorMessage = err.response.data.errors[errorGroupKey][errorMessageKey]
                            this.errors = this.errors.concat(errorMessage)
                        }
                    }
                })
        },
    },
}
</script>
